import TextField from '@mui/material/TextField';
import { Image } from '@src/API';
import useWindowDimensions from '@src/hooks/useWindowDimensions';
import { ChatContextValue, useChatState } from '@src/providers/ChatProvider';
import { MD_SCREEN_IN_PX } from '@src/utils/constants';
import debounce from 'lodash/debounce';
import dynamic from 'next/dynamic';
import { useEffect, useRef, useState } from 'react';

const UploadInboxPhotoButton = dynamic(import('@src/components/upload/UploadInboxPhotoButton'));

import IconSendMessageDisableSVG from '/public/ic-send-message-disable.svg';
import IconSendMessageEnableSVG from '/public/ic-send-message-enable.svg';

type InboxInputFieldProps = {
  placeholder: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onSend: () => void;
  onAddNewLine?: () => void;
  disabled?: boolean;
  minRows?: number;
  maxRows?: number;
  isMobile: boolean;
};

const SmInputClassName = `border border-1 border-greyscale-g3
    rounded-full py-2 px-4 w-full
    placeholder:text-content-t300 outline-none flex items-center`;

export function InboxInputField(props: InboxInputFieldProps) {
  const {
    placeholder,
    value,
    onChange,
    onSend,
    onAddNewLine,
    minRows = 1,
    maxRows = 2,
    disabled,
    isMobile,
  } = props;

  const inputRef = useRef<HTMLDivElement>(null);

  /** Handle on Enter press: If on mobile, Enter should add new line. Otherwise:
   * - If key is Enter. Send message
   * - If key is Enter + Shift/Control/Alt/Command, add new line
   *
   * This is Facebook's behavior
   */
  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    // If on mobile, use the default behavior of Enter: add new line
    // If not on mobile:
    if (e.key === 'Enter' && !isMobile) {
      if (e.ctrlKey || e.shiftKey || e.metaKey || e.altKey) {
        // If the other key is Shift/Control/Command, add new line
        e.preventDefault();
        onAddNewLine?.();
      } else {
        // If key is only Enter. Send message
        e.preventDefault();
        onSend?.();
      }
    }
  };

  return (
    <div className="w-full">
      <div className={SmInputClassName}>
        <TextField
          sx={{
            width: '100%',
            '.MuiInputBase-root': {
              padding: '0px',
            },
            '.MuiInputBase-input': {
              fontFamily: 'Sarabun',
              fontSize: '16px',
              color: '#303030',
              '&::placeholder': {
                color: '#717171',
                opacity: 1,
              },
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: 'hidden',
              },
            },
          }}
          onKeyDown={handleKeyDown}
          multiline
          minRows={minRows}
          maxRows={maxRows}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          disabled={disabled}
          inputRef={inputRef}
        />
        <button
          type="button"
          onClick={() => {
            inputRef.current?.focus();
            onSend();
          }}
          className="shrink-0 flex items-center justify-center"
        >
          <div className="flex items-center justify-center h-6 w-6">
            {value.length === 0 ? <IconSendMessageDisableSVG /> : <IconSendMessageEnableSVG />}
          </div>
        </button>
      </div>
    </div>
  );
}

export default function MessageInputView({ isChatWidget }: { isChatWidget?: boolean }) {
  /* States */
  const { selectedUserChannelId, sendMessage } = useChatState() as ChatContextValue;
  const [value, setValue] = useState('');
  const { width: windowInnerWidth } = useWindowDimensions();
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    if (!windowInnerWidth) return;
    if (windowInnerWidth < MD_SCREEN_IN_PX) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [windowInnerWidth]);

  /* Functions */

  const handleChangeMessage = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);
  };

  const handleButtonSend = async () => {
    const trimmedValue = value.trim();
    setValue('');
    // Send the message
    await sendMessage({
      text: trimmedValue,
      images: [],
    });
  };

  const handleUploadedImages = async (messageId: string, uploadedImages: Image[]) => {
    await sendMessage({
      messageId,
      text: '',
      images: uploadedImages,
    });
  };

  const handleOnAddANewLine = () => {
    setValue((prev) => {
      return `${prev + '\r\n'}`;
    });
  };

  /* Render */
  return (
    <div className={`w-full bg-white ${isChatWidget ? 'pl-1 pr-2 py-2' : 'pl-2 pr-4 py-3'} `}>
      {selectedUserChannelId && (
        <div className="space-x-1 flex items-end justify-center">
          <UploadInboxPhotoButton handleUploadedImages={handleUploadedImages} />

          <InboxInputField
            placeholder={isMobile ? 'Nhập tin nhắn...' : 'Nhấn Enter để gửi...'}
            value={value}
            onSend={debounce(handleButtonSend, 200)}
            onChange={handleChangeMessage}
            onAddNewLine={handleOnAddANewLine}
            isMobile={isMobile}
          />
        </div>
      )}
    </div>
  );
}
