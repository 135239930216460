import { getMeta } from '@components/buttons/UppyUploadPhotoButton';
import MainAvatar from '@components/profile/MainAvatar';
import Collapse from '@mui/material/Collapse';
import Tooltip from '@mui/material/Tooltip';
import { captureException } from '@sentry/nextjs';
import { Image as ImageData, Message, User, UserChannel } from '@src/API';
import useWindowDimensions from '@src/hooks/useWindowDimensions';
import { ChatContextValue, useChatState } from '@src/providers/ChatProvider';
import { LG_SCREEN_IN_PX, XL_SCREEN_IN_PX } from '@src/utils/constants';
import { convertISOStringToReadableDateString } from '@src/utils/dates';
import { customImageLoader, getImageSrcUrl, getThumbnailUrl } from '@src/utils/media';
import dayjs from 'dayjs';
import NextImage from 'next/image';
import Link from 'next/link';
import 'photoswipe/dist/photoswipe.css';
import { useEffect, useState } from 'react';
import { Gallery, Item } from 'react-photoswipe-gallery';

type MessageItemViewProps = {
  isFirstMessage: boolean;
  users: User[];
  receiverUserChannel: UserChannel | null | undefined;
  message: Message;
  shouldShowAvatar: boolean;
  isMyMessage: boolean;
  isChatWidget?: boolean;
};

function MessageContentView({
  content,
  isMyMessage,
  isChatWidget,
}: {
  content: string | undefined | null;
  isMyMessage: boolean;
  isChatWidget: boolean;
}) {
  const urlRegex = /(https?:\/\/[^\s]+)/g;

  // Split the content based on urlRegex
  return (
    <div
      className={`break-words text-start text-m ${
        isChatWidget ? 'px-3 py-2' : 'px-3 py-3'
      }  whitespace-pre-wrap ${isMyMessage ? 'bg-primary-p1' : 'bg-greyscale-g1'} rounded-lg`}
      style={{ wordBreak: 'break-word' }}
    >
      {content?.split(urlRegex).map((part, index) => {
        // If part is a link, insert <a> tag
        if (part.match(urlRegex)) {
          return (
            <a
              key={index}
              href={part}
              className="text-primary-p4 hover:underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              {part}
            </a>
          );
        }
        return part;
      })}
    </div>
  );
}

export default function MessageItemView(props: MessageItemViewProps) {
  const {
    isFirstMessage,
    users,
    receiverUserChannel,
    message,
    shouldShowAvatar,
    isMyMessage,
    isChatWidget = false,
  } = props;
  const { pendingMessageIds } = useChatState() as ChatContextValue;
  const { isMobile } = useWindowDimensions();
  const [expanded, setExpanded] = useState(false);

  const [oldImageUrlWidth, setOldImageUrlWidth] = useState(250);
  const [oldImageUrlHeight, setOldImageUrlHeight] = useState(250);

  const listImages = (message.images?.items as ImageData[]) || [];
  const isPendingMessage = pendingMessageIds.some(
    (pendingMessageId) => pendingMessageId === message.id,
  );

  const getClassName = (isChatWidget: boolean, listImages: ImageData[]) => {
    if (isChatWidget) {
      if (listImages.length === 1) {
        return 'grid-cols-1';
      } else {
        return 'grid-cols-2';
      }
    }
    return 'grid-cols-3 md:grid-cols-4 lg:grid-cols-5';
  };

  // TODO (long): Add isMounted to prevent memory leak
  useEffect(() => {
    const fetchImageSize = async () => {
      try {
        if (!message.image_url) return;
        const img = await getMeta(message.image_url);
        setOldImageUrlWidth(img.width);
        setOldImageUrlHeight(img.height);
      } catch (error) {
        captureException(error);
      }
    };
    fetchImageSize();
  }, [message]);

  /* UIs */
  const sender = users.find((user) => user.id === message.sender_id);
  // If sender is the same as receiverUserChannel, don't show read status because
  // that means authUser is looking at the other user's message
  let readStatus = '';
  if (receiverUserChannel?.user_id !== sender?.id) {
    if (isPendingMessage) {
      readStatus = 'Đang gửi'; // Not delivered
    } else if (
      dayjs(receiverUserChannel?.update_date_time).isBefore(dayjs(message.publication_date_time))
    ) {
      readStatus = 'Đã gửi'; // Delivered
    } else {
      readStatus = 'Đã xem'; // Read
    }
  }

  return (
    <div
      className={`flex space-x-2 ${isChatWidget ? '' : 'md:space-x-3'}  ${
        isFirstMessage ? '' : shouldShowAvatar ? 'mt-6' : 'mt-2'
      }`}
    >
      {/* Profile picture, if any */}
      {shouldShowAvatar && (
        <div className="flex shrink-0">
          <MainAvatar
            sizeType="xs"
            imageSrc={sender?.profile_picture_url || ''}
            linkUrl={`/users/${sender?.username || ''}`}
            displayName={sender?.display_name || 'Người dùng'}
            canOpenProfile={true}
            isOpenNewTab={false}
          />
        </div>
      )}
      {/* Empty space */}
      {!shouldShowAvatar && <div className="w-8 rounded-full" />}

      <div className="flex flex-1 flex-col">
        {/* Display name: Show next to profile picture */}
        {shouldShowAvatar && (
          <Link href={`/users/${sender?.username || ''}`} passHref>
            <a className="pb-2">
              <span className={`text-m font-semibold hover:underline`}>{sender?.display_name}</span>
            </a>
          </Link>
        )}

        {/* Images Message */}
        {message.image_url && (
          <div className="relative group flex flex-col">
            <Gallery>
              <div className="grid gap-2 grid-cols-3">
                <Item
                  key={message.id}
                  original={getImageSrcUrl(message.image_url)}
                  thumbnail={getThumbnailUrl(message.image_url)}
                  width={oldImageUrlWidth}
                  height={oldImageUrlHeight}
                >
                  {({ ref, open }) => (
                    <div ref={ref as React.MutableRefObject<HTMLImageElement>} className="flex">
                      <div className="relative aspect-square w-full h-full group flex flex-col">
                        <NextImage
                          objectFit="cover"
                          loader={customImageLoader}
                          src={message.image_url || ''}
                          onClick={open}
                          alt="Gallery photo"
                          layout="fill"
                        />
                      </div>
                    </div>
                  )}
                </Item>
              </div>
            </Gallery>
          </div>
        )}

        {listImages.length !== 0 && (
          <div className={`w-full grid gap-2 ${getClassName(isChatWidget, listImages)}`}>
            <Gallery>
              {listImages.map((image) => (
                <Item
                  key={image.id}
                  original={getImageSrcUrl(image.src_url)}
                  thumbnail={getThumbnailUrl(image.thumbnail_url)}
                  width={image.width || 0}
                  height={image.height || 0}
                >
                  {({ ref, open }) => (
                    <div
                      ref={ref as React.MutableRefObject<HTMLImageElement>}
                      className="relative rounded-lg overflow-hidden aspect-square
                          border border-greyscale-g2
                          hover:border-greyscale-g3 hover:bg-greyscale-g1"
                    >
                      <div className="relative w-full h-full group flex flex-col">
                        <NextImage
                          src={image.thumbnail_url || ''}
                          layout="fill"
                          objectFit="cover"
                          onClick={open}
                          loader={customImageLoader}
                          alt={`Gallery photo ${image.id}`}
                          sizes={`(max-width: ${LG_SCREEN_IN_PX}px) 33vw,
                                (max-width: ${XL_SCREEN_IN_PX}px) 25vw,
                                20vw`}
                        />
                      </div>
                    </div>
                  )}
                </Item>
              ))}
            </Gallery>
          </div>
        )}

        {/* Text Message */}
        <Tooltip
          title={`${convertISOStringToReadableDateString(
            message.publication_date_time,
            'DD/MM HH:mm',
          )} ${readStatus ? `· ${readStatus}` : ''}`}
          placement="left-end"
          open={isMobile ? false : undefined}
        >
          <div className="flex flex-col w-fit">
            <div
              className="flex flex-row space-x-1 items-center"
              onClick={() => setExpanded(!expanded)}
            >
              {message.content && (
                <MessageContentView
                  content={message.content}
                  isMyMessage={isMyMessage}
                  isChatWidget={isChatWidget}
                />
              )}
              {isPendingMessage && (
                <div className="w-3.5 h-3.5 border-2 border-greyscale-g3 rounded-full" />
              )}
            </div>

            {isMobile && (
              <Collapse in={expanded} timeout="auto" unmountOnExit>
                <span className="text-xs text-content-t300">
                  {convertISOStringToReadableDateString(
                    message.publication_date_time,
                    'DD/MM HH:mm',
                  )}{' '}
                  {readStatus ? `· ${readStatus}` : ''}
                </span>
              </Collapse>
            )}
          </div>
        </Tooltip>
      </div>
    </div>
  );
}
